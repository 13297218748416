import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { templateListActions } from './template-list';
import { map, switchMap } from 'rxjs';
import { FusionClient, RequestBuilder } from '@ssi/fusion';
import { TemplateQuery, TemplateQueryResult } from './template-list.types';
import { FusionTemplates } from '../../app.constants';

export const queryEffect = createEffect(
  (actions$ = inject(Actions), fusion = inject(FusionClient)) => {
    return actions$.pipe(
      ofType(templateListActions.query),
      map(data => data.search),
      switchMap((search: TemplateQuery) => {
        const request = RequestBuilder.fromTemplateId(FusionTemplates.QueryTemplateList)
          .withParameter('byName', search.byName, 'varchar', 'ejs')
          .withParameter('byDescription', search.byDescription, 'varchar', 'ejs')
          .withParameter('byOwner', search.byOwner, 'varchar', 'ejs')
          .withParameter('byQueryType', search.byQueryType, 'varchar', 'ejs')
          .withParameter('byReleaseStatus', search.byReleaseStatus, 'varchar', 'ejs')
          .withParameter('byUserType', search.byUserType, 'varchar', 'ejs')
          .withParameter('bySql', search.bySql, 'varchar', 'ejs')
          .withParameter('orderBy', search.orderBy, 'varchar', 'ejs')
          .build();
        return fusion.send<TemplateQueryResult[]>(request);
      }),
      map(response => templateListActions.fetchSuccess({ list: response.ok ? response.data! : [] }))
    );
  }, { functional: true });
