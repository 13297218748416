import { Actions, createEffect, ofType } from "@ngrx/effects";
import { inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { getRouterSelectors, routerNavigatedAction } from "@ngrx/router-store";
import { catchError, distinctUntilChanged, EMPTY, filter, map, switchMap } from "rxjs";
import { RouteNames } from "../../app.constants";
import { templateListActions, templateListFeature } from "./template-list";

export const loadEffect = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    const { selectRouteData } = getRouterSelectors();
    return actions$.pipe(
      ofType(routerNavigatedAction),
      distinctUntilChanged(),
      switchMap(() => store.select(selectRouteData)),
      filter(data => data['routeName'] === RouteNames.TemplateList),
      switchMap(() => store.select(templateListFeature.selectSearch)),
      map(search => templateListActions.query({ search })),
      catchError(() => EMPTY)
    );
  }, { functional: true });
