import { createActionGroup, createFeature, createReducer, emptyProps, on, props } from "@ngrx/store";
import { isEmpty } from 'lodash';
import { produce } from "immer";
import { loadEffect } from "./load.effect";
import { deleteEffect } from "./delete.effect";
import { copyEffect } from "./copy.effect";
import { addEffect } from "./add.effect";
import { TemplateListState, TemplateQuery, TemplateQueryResult } from "./template-list.types";
import { OwnerInfo } from '../../pages/template-list/template-search/template-search.component';
import { loadOwnersEffect } from './load-owners.effect';
import { queryEffect } from './query.effect';

const defaultTemplateListState: TemplateListState = {
  list: [],
  search: { byReleaseStatus: 'all' },
  loaded: false,
  ownerList: []
};

export const templateListActions = createActionGroup({
  source: 'Template List',
  events: {
    'Fetch Init': emptyProps(),
    'Fetch Success': props<{ list: TemplateQueryResult[] }>(),
    'Fetch Owners': emptyProps(),
    'Fetch Owners Success': props<{ list: OwnerInfo[] }>(),
    'Query': props<{ search: TemplateQuery }>(),
    'Delete': props<{ templateId: string, userUuid: string }>(),
    'Delete Success': props<{ templateId: string }>(),
    'Copy': props<{ templateId: string, userUuid: string }>(),
    'Add': props<{ userUuid: string }>(),
    'Alter Success': emptyProps() // add or copy success
  }
});

export const templateListFeature = createFeature({
  name: 'templateList',
  reducer: createReducer(
    defaultTemplateListState,
    on(templateListActions.query,
      (state, { search }) => produce(state, draft => {
        draft.search = isEmpty(search) ? defaultTemplateListState.search : search;
      })),
    on(templateListActions.alterSuccess,
      state => produce(state, draft => {
        draft.loaded = false;
        draft.list = [];
      })),
    on(templateListActions.fetchInit,
      state => produce(state, draft => {
        draft.loaded = false;
      })),
    on(templateListActions.fetchSuccess,
      (state, { list }) => produce(state, draft => {
        draft.list = list;
        draft.loaded = true;
      })),
    on(templateListActions.fetchOwnersSuccess,
      (state, { list }) => produce(state, draft => {
        draft.ownerList = list;
        draft.loaded = true;
      })),
    on(templateListActions.deleteSuccess, (state, { templateId }) => {
      const filteredList = state.list.filter(template => template.templateId !== templateId);
      return produce(state, draft => {
        draft.list = filteredList;
        draft.loaded = true;
      });
    })
  )
});
export const templateListEffects = { loadEffect, deleteEffect, copyEffect, addEffect, loadOwnersEffect, queryEffect };
